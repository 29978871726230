/*----------------------------------------
        06. Intro CSS
----------------------------------------*/

@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

.intro-slider {
  &:hover {
    .main-slider-nav {
      opacity: 1;
      visibility: visible;
    }
  }
  .main-slider-nav {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.25);
    width: 100px;
    height: 100px;
    line-height: 100px;
    display: block;
    text-align: center;
    color: $white;
    opacity: 0;
    visibility: hidden;
    @include common-transition;
    &::after {
      display: none;
    }

    i {
      font-size: 40px;
      line-height: 1;
      vertical-align: middle;

      @media #{$laptop-device} {
        font-size: 30px;
      }

      @media #{$tablet-device} {
        font-size: 24px;
      }

      @media #{$desktop-device} {
        font-size: 24px;
      }

      @media #{$large-device} {
        font-size: 24px;
      }
    }

    &:hover {
      color: #1292ee;
    }

    @media #{$laptop-device} {
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
    @media #{$tablet-device} {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
    @media #{$desktop-device} {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
    @media #{$large-device} {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }

  .home-slider-prev {
    left: 30px;
  }

  .home-slider-next {
    right: 30px;

    &::after {
      display: none;
    }
  }
}

/* slider */
.intro-slider-wrap {
  background-color: #fff;
  margin-top: -112px;
  padding-top: 100px;

  @media #{$extra-small-device} {
    @include flex-center;
    background-color: transparent;
  }

  .swiper-container {
    @media #{$extra-small-device} {
      padding: 30px 0;
    }
  }

  .slide1 {
    .slider1__btn {
      margin-right: 10px;
      transition: ease-out 0.4s;

      &:hover {
        box-shadow: inset 400px 0 0 0 $themecolor;
      }
    }
  }

  .slider1__btn2 {
    background-color: $mate-black;
    border-radius: 5px;
  }

  .swiper-button-prev {
    left: 0px !important;
    background-image: linear-gradient(93deg, $gradient-one 0%, $gradient-two 100%) !important;
    color: #fff !important;
    padding: 30px;
    border-radius: 50%;
    transition: ease-out 0.4s;

    &:hover {
      background-color: $themecolor !important;
      overflow: hidden;

      &::after {
        animation: moveoutleft 1s linear;
      }

      @keyframes moveoutleft {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100px);
        }
      }
    }

    @media #{$extra-small-device} {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0px !important;
    background-image: linear-gradient(93deg, $gradient-one 0%, $gradient-two 100%) !important;
    color: #fff !important;
    padding: 30px;
    border-radius: 50%;
    transition: ease-out 0.4s;

    &:hover {
      background-color: $themecolor !important;
      overflow: hidden;

      &::after {
        animation: moveoutright 1s linear;
      }

      @keyframes moveoutright {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(100px);
        }
      }
    }

    @media #{$extra-small-device} {
      display: none;
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet-active {
      &::before {
        background: $themecolor;
      }
    }
  }

  .swiper-pagination {
    margin-bottom: 20px;
    bottom: -3px !important;
    @media #{$min-576-device} {
      display: none;
    }
  }

  .swiper-slide {
    overflow: hidden;
  }
}

.swiper-slide-active .intro-content > * {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.swiper-slide-active .intro-content > *:nth-child(1) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.swiper-slide-active .intro-content > *:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.swiper-slide-active .intro-content > *:nth-child(3) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.swiper-slide-active .intro-content > *:nth-child(4) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.swiper-slide-active .intro-content > *:nth-child(5) {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.swiper-slide-active .intro-content > *:nth-child(6) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.innerslider {
  height: 80vh;
  position: relative;
  z-index: 99;
  width: 100%;
  margin: auto;
  overflow: visible;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: $white;
}

.mySwiper {
  height: 80vh;
  width: 100%;

  @media #{$max-wid-device} {
    height: 100%;
  }
}

.slide2 {
  display: flex;
  align-items: center;
  position: relative;
  height: 80vh;
  padding-bottom: 40px;
  text-align: left;

  @media #{$extra-small-device} {
    padding-bottom: 0px;
  }
}

@media #{$max-wid-device} {
  .slide2 {
    height: 100%;
    margin: 20px auto;
  }
}

@media #{$extra-small-device} {
  .slide2 {
    height: auto;
    margin: auto;
  }
}

.slide2 {
  @media #{$extra-small-device} {
    text-align: center;
  }
  h3 {
    font-size: 2rem;
    font-weight: 900;
    @media #{$extra-small-device} {
      font-size: inherit;
      line-height: 1.5;
    }
  }

  .highlighttext {
    color: $themecolor;
    font-size: 2rem;
    @media #{$extra-small-device} {
      font-size: inherit;
    }
  }

  .outer__slide1__img {
    background-position: center center;
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin-left: -85px;

    @media #{$extra-small-device} {
      height: auto;
      background-image: none !important;
    }

    img {
      @include flex-center;
      margin: auto;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0;
      right: 0;
      max-width: 100%;
      width: 50%;

      @media #{$extra-small-device} {
        position: inherit;
        height: 280px;
        object-fit: contain;
        margin-bottom: 40px;
        width: 100%;
      }
    }
  }
}

@media #{$custom-max-1399-device} {
  .slide2 .outer__slide1__img {
    margin-left: 0;
  }
}

@media #{$min-768-device} {
  .slide2 .outer__slide1__img img {
    left: 0;
    top: 0;
  }
}

.swiper-button-prev::after {
  font-size: 0.8rem !important;
}

.swiper-button-next::after {
  font-size: 0.8rem !important;
}

.outer__slide1__img img {
  animation: updown 1s infinite alternate;
}

@keyframes updown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.slider1__btn {
  transition: ease-out 2s;
  display: inline-block;
  box-shadow: inset 0 0 0 0 #313131;
  border: none;

  &:hover {
    box-shadow: inset 400px 0 0 0 #313131;
  }
}

.slider1__btn2 {
  transition: ease-out 2s;
  display: inline-block;
  box-shadow: inset 0 0 0 0 $themecolor;

  &:hover {
    box-shadow: inset 400px 0 0 0 $themecolor;
  }
}

.slider__content {
  h3,
  p {
    color: #313131;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media #{$extra-small-device} {
      -webkit-line-clamp: 1;
    }
  }
}

.no_data {
  display: flex;
  height: 100vh;
  color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -70px;

  @media #{$max-wid-device} {
    position: relative;
  }
}

.loading_data {
  position: absolute;
  top: 9%;
  width: 73%;
  left: 0;
  right: 0;
  margin: auto;

  @media #{$max-wid-device} {
    position: relative;
  }
}

// choose us
.chooseus {
  .title {
    h2 {
      text-align: center;
      font-size: 34px;
      text-transform: capitalize;
      margin-bottom: 50px;
      line-height: 1.5;
      font-weight: bold;
    }

    .left {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 3px;
      background: $themecolor;
      margin-right: 20px;
      top: -6px;

      @media #{$large-device} {
        display: none;
      }
    }
    .right {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 3px;
      background: $themecolor;
      margin-left: 20px;
      top: -6px;

      @media #{$large-device} {
        display: none;
      }
    }
  }

  .why-choose-one__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .tab-buttons {
      position: relative;
      display: block;

      .tab-btn {
        position: relative;
        display: block;
        color: $mate-black;
        font-size: 20px;
        font-weight: 600;
        border-radius: 7px;
        background-color: #ffffff;
        box-shadow: 0px 0px 60px 0px rgb(0, 0, 0, 0.07);
        padding: 41px 20px 40px;
        text-align: center;
        margin-bottom: 20px;
        transition: all 200ms linear;
        transition-delay: 0.1s;
        z-index: 1;

        &:hover {
          color: #fff;
          &::before {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            transform-origin: top center;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0px;
          right: 0;
          border-radius: 7px;
          background-color: $themecolor;
          transition: -webkit-transform 0.4s ease;
          transition: transform 0.4s ease;
          transition:
            transform 0.4s ease,
            -webkit-transform 0.4s ease;
          transform-origin: bottom right;
          -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
          z-index: -1;
        }
      }
    }

    .tabs-content {
      position: relative;
      display: block;
      margin-left: 10px;

      @media #{$large-device} {
        display: flex;
        justify-content: center;
      }

      .tabs-content__inner {
        position: relative;
        display: block;

        .tabs-content__inner-bg {
          position: absolute;
          top: 0;
          right: 0;
          width: 545px;
          min-height: 380px;
          background-attachment: scroll;
          background-repeat: no-repeat;
          border-radius: 11px;
          background-position: center;

          @media #{$max-wid-device} {
            display: none;
          }
        }

        .tabs-content__list {
          position: relative;
          display: block;
          max-width: 425px;
          width: 100%;
          z-index: 5;

          @media #{$max-wid-device} {
            max-width: 100%;
          }

          ul {
            position: relative;
            display: block;
            margin-left: -10px;
            margin-right: -10px;

            @media #{$max-wid-device} {
              margin-left: 0px;
              margin-right: 0px;
              padding-left: 0;
            }

            li.mt30 {
              margin-top: 30px;

              @media #{$max-wid-device}{
                margin-top: 0;
              }
            }

            li {
              position: relative;
              display: block;
              width: 50%;
              float: left;
              padding: 0px 10px 0px;

              @media #{$max-wid-device}{
                margin-bottom: 20px;
              }

              p {
                margin-bottom: 0;
                font-size: 18px;
              }

              &:hover {
                .inner {
                  img {
                    background: #fff;
                    border-radius: 10px;
                  }
                  &::before {
                    -webkit-transform: scale(1, 1);
                    transform: scale(1, 1);
                    transform-origin: top center;
                  }

                  h4 {
                    p {
                      color: #fff;
                    }
                  }
                }
              }

              .inner {
                position: relative;
                display: block;
                background: #ffffff;
                box-shadow: 0px 0px 35px 0px rgb(0, 0, 0, 0.05);
                border: 1px solid #f2f4fb;
                padding: 31px 0px 22px;
                border-radius: 5px;
                z-index: 1;
                transition: all 200ms linear;
                transition-delay: 0.1s;

                .icon-box {
                  img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                  }
                }

                p {
                  color: $mate-black;
                  font-weight: 600;
                }
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0px;
                  right: 0;
                  border-radius: 5px;
                  background-color: $themecolor;
                  transition: -webkit-transform 0.4s ease;
                  transition: transform 0.4s ease;
                  transition:
                    transform 0.4s ease,
                    -webkit-transform 0.4s ease;
                  transform-origin: bottom right;
                  -webkit-transform: scale(1, 0);
                  transform: scale(1, 0);
                  z-index: -1;
                }

                .icon-box {
                  position: relative;
                  display: block;
                  margin-bottom: 13px;

                  span {
                    &::before {
                      position: relative;
                      display: inline-block;
                      color: var(--thm-base);
                      font-size: 40px;
                      transition: all 200ms linear;
                      transition-delay: 0.1s;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// feature
.quiz_features {
  .title {
    h2 {
      text-align: center;
      font-size: 34px;
      text-transform: capitalize;
      margin-bottom: 50px;
      line-height: 1.5;
      font-weight: bold;
    }

    .left {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 3px;
      background: $themecolor;
      margin-right: 20px;
      top: -6px;

      @media #{$large-device} {
        display: none;
      }
    }
    .right {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 3px;
      background: $themecolor;
      margin-left: 20px;
      top: -6px;

      @media #{$large-device} {
        display: none;
      }
    }
  }

  .feature_box {
    display: flex;
    flex-wrap: wrap;

    .feature-box {
      flex: 0 0 auto;
      width: 50%;

      @media #{$max-wid-device} {
        width: 100%;
      }
    }

    .inner_feature {
      background-color: #f5f5f8;
      margin-right: 15px;
      border-radius: 10px;
      padding: 40px 20px;
      margin-bottom: 15px;
      box-shadow: 0px 3px 6px $themecolor;
      position: relative;
      transition: all 200ms linear;
      transition-delay: 0.1s;
      z-index: 1;

      &:hover {
        p {
          color: #fff;
        }
        &::before {
          transform: scale(1, 1);
          transform-origin: bottom center;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0px;
        right: 0;
        border-radius: 5px;
        background-color: $themecolor;
        transition: -webkit-transform 0.4s ease;
        transition: transform 0.4s ease;
        transition:
          transform 0.4s ease,
          -webkit-transform 0.4s ease;
        transform-origin: top left;
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
        z-index: -1;
      }

      .feature-title {
        p {
          text-transform: capitalize;
          font-size: 18px;
          font-weight: 600;
          color: $mate-black;
        }
      }

      .feature_desc {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .feature_right {
    img {
      height: 400px;
      width: 100%;
      object-fit: contain;
    }
  }
}

.work-process-one {
  position: relative;
  display: block;
  padding: 50px 0px 90px;
  counter-reset: count;
  z-index: 1;
  .shape2 {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;

    @media #{$laptop-device}{
      top: 143px;
    }

    @media #{$max-wid-device}{
      display: none;
    }
  }
  .title {
    h2 {
      text-align: center;
      font-size: 34px;
      text-transform: capitalize;
      margin-bottom: 50px;
      line-height: 1.5;
      font-weight: bold;
    }

    .left {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 3px;
      background: $themecolor;
      margin-right: 20px;
      top: -6px;

      @media #{$large-device} {
        display: none;
      }
    }
    .right {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 3px;
      background: $themecolor;
      margin-left: 20px;
      top: -6px;

      @media #{$large-device} {
        display: none;
      }
    }
  }

  .work-process-one__single {
    position: relative;
    display: block;
    margin-bottom: 30px;

    &.style2 {
      .work-process-one__single-icon .count-box {
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0px;
    }

    }
    &.mt100 {
      margin-top: 100px;

      @media #{$max-wid-device}{
        margin-top: 0;
      }
    }

    &.mt50 {
      margin-top: 50px;

      @media #{$max-wid-device}{
        margin-top: 0;
      }
    }

    .work-process-one__single-icon {
      position: relative;
      display: block;
      width: 150px;
      margin: 0 auto 23px;

      .inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 1px solid rgba(121, 184, 35, 0.2);
        margin: 0 auto;
        z-index: 1;
        background-color: #f5f5f8;



        &::before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: 50%;
          background-image: -moz-radial-gradient(50% 50%, circle closest-side, rgb(121, 184, 35) 44%, rgb(255, 255, 255) 100%);
          background-image: -webkit-radial-gradient(50% 50%, circle closest-side, rgb(121, 184, 35) 44%, rgb(255, 255, 255) 100%);
          background-image: -ms-radial-gradient(50% 50%, circle closest-side, rgb(121, 184, 35) 44%, rgb(255, 255, 255) 100%);
          opacity: 0.15;
          content: "";
          z-index: -1;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: $themecolor;
          border-radius: 50%;
          transform: scale(0.5);
          opacity: 0;
          -webkit-transition: all 0.4s linear;
          -o-transition: all 0.4s linear;
          transition: all 0.4s linear;
          z-index: -1;
        }

        img {
          width: 60px;
          height: 60px;
        }
      }

      .count-box {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 15px;
        background: $themecolor;
        color: #ffffff;
        border-radius: 50%;
        font-weight: 400;
        letter-spacing: -0.02em;
        z-index: 1;
        transition: all 400ms linear;
        transition-delay: 0.1s;

        &::before {
          top: -35px;
          left: 70px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 15px;
          border-radius: 50%;
          font-weight: 400;
          counter-increment: count;
          content: "0" counter(count);
        }
      }
    }
  }

  .work-process-one__single-content {
    position: relative;
    display: block;

    h2 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      text-transform: capitalize;
      letter-spacing: -0.02em;
      margin-bottom: 2px;

      a {
        color: $mate-black;
        transition: all 200ms linear;
        transition-delay: 0.1s;
      }
    }

    p {
      margin: 0;
    }
  }
}
